body {
  margin: 0;
  font-feature-settings: "liga" 0;
  font-family: 'ralewayregular';
}

img {
  max-width: 100%;
}

h1 {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-top: 3rem !important;
  margin-bottom: 2.5rem;
}

a {
  color: #32913f;
}

a:hover {
  color: #32913f;
}

.background-red {
  background-color: #9a000a;
}

.background-green {
  background-color: #32913f;
}

.background-brown {
  background-color: #333333;
}

.text-green {
  color: #32913f;
}

.btn-green {
  color: #fff;
  background-color: #32913f;
  border-color: #32913f;
  transition: all 1s;
}

.btn-green:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-brown {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  transition: all 1s;
}

.btn-brown:hover {
  color: #fff;
  opacity: 0.8;
}

.link-white {
  color: #ffffff !important;
}

.fixed-button {
  position: fixed;
  color: #fff;
  padding: 14px 14px;
  z-index: 999;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.50);
  transition: all 1s;

  i {
    margin-right: 8px;
  }
}

.fixed-button:hover {
  color: #fff;
  text-decoration: none;
}

.call-button {
  background-color: #32913f;
  top: 30px;
  right: -128px;
}

.call-button:hover {
  right: 0px;
}

.client-button {
  background-color: #333;
  top: 90px;
  right: -112px;
}

.client-button:hover {
  right: 0px;
}

#header .logo {
  width: 215px;
  margin-top: 35px;
  margin-bottom: 35px;
}

#header-home {
  background: url('../images/background-logo.png') 0px 169px repeat-x;

  .logo {
    width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

#header-image {
  width: 100%;
  max-height: 350px;
  text-align: center;
  background: #333333;

  img {
    max-height: 350px;
  }
}

#header .navbar {
  margin-top: 92px;
}

.nav {
  a {
    color: #000;
    font-size: 26px;
    transition: color 1s ease;
  }

  a:hover, li.active a, li:hover a {
    color: #32913f;
    text-decoration: none;
  }

  li {
    border-bottom: 2px solid transparent;
    margin-right: 30px;
    position: relative;

    :after {
      content: '';
      display: block;
      border-bottom: 2px solid #32913f;
      width: 0;
      position: absolute;
      right: 0;
      transition: width 1s ease;
    }
  }

  li:hover, li.active {
    border-bottom: 2px solid #32913f;

    :after {
      width: 100%;
    }
  }

  li:last-child {
    margin-right: 0px;
  }
}

.bx-wrapper {
  border: none;
  box-shadow: none;
}

#footer {
  font-family: 'genevaregular';
  background-color: #333333;
  color: #fff;

  .logo {
    width: 275px;
  }

  i {
    background-color: #32913f;
    border-radius: 50%;
    font-size: 1.9rem;
    padding: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
  }

  .information {
    width: 330px;
    margin: 0px auto 3rem auto;
  }
}

@media (max-width: 1200px) {
  #header .navbar {
    margin-top: 33px;
  }
}

@media (max-width: 992px) {
  #header .navbar {
    margin-top: 0px;
  }
}
