div[data-module="reference"] {
  .reference {
    position: relative;
    cursor: pointer;
    transition: all 1s;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 234px;
  }

  .reference img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  .reference-detail {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #fff;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    transition: all 1s;
  }

  .reference:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.50);
  }

  .reference:hover .reference-detail {
    visibility: visible;
    opacity: 0.8;
  }
}
