div[data-module="contact"] {
  .input-group-text {
    border: 1px solid #333333;
    background-color: #333333;
    color: #fff;
  }

  input, textarea {
    border: 1px solid #333333;
  }

  textarea {
    height: 250px;
  }

  input[type="submit"] {
    background-color: #333333;
    color: #fff;
  }
}
