div[data-module="home"] {
  .bx-wrapper .bx-prev,
  .bx-wrapper .bx-next {
    background: none;
    color: #fff;
    text-transform: none;
    text-align: center;
    font-size: 3rem;
  }

  .bx-wrapper .bx-controls-direction a {
    text-indent: 0;
  }

  .bxslider {

    .slider {
      max-width: 1386px;
      margin: 0 auto;
      position: relative;
    }

    h1, h2 {
      color: #ffffff;
      margin: 0 !important;
      padding: 0 !important;
    }

    .title {
      position: absolute;
      top: 4%;
    }

    #slider1 .title {
      width: 100%;
      left: 0%;
      text-align: center;
    }

    #slider2 .title {
      width: 100%;
      left: 0%;
      text-align: center;
    }

    #slider3 .title {
      width: 100%;
      left: 0%;
      text-align: center;
    }

    .slider-curve {
      .text {
        position: absolute;
        color: white;
        font-size: 20px;
      }

      .text-1 {
        bottom: 34%;
        left: 24%;
      }

      .text-2 {
        bottom: 11%;
        left: 46%;
      }

      .text-3 {
        bottom: 40%;
        left: 38%;
      }

      .text-4 {
        bottom: 29%;
        left: 66%;
      }

      .text-5 {
        bottom: 66%;
        left: 57%;
      }

      .text-6 {
        bottom: 79%;
        left: 67%;
      }
    }
  }

  .skills {
    margin-top: 6rem;

    .skill {
      transition: all 1s;

      .logo {
        width: 180px;
        height: 180px;
        color: #333333;
        text-align: center;
        border-radius: 50%;
        border: 8px solid #333333;
        transition: all 1s;
      }

      i {
        font-size: 5.5rem;
      }
    }

    .skill:hover {
      color: #32913f!important;

      .logo {
        border-color: #32913f;
        color: #32913f;
      }
    }
  }



  .references {
    margin-top: 6rem;

    .title {
      background: url('../images/background-realisations.png') #fff repeat-x left 18px;

      span {
        background: #fff;
        padding: 0 20px;
      }
    }
  }
}
