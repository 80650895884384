div[data-module="offer"] {
  .logo {
    background-color: #32913f;
    font-size: 3rem;
    color: #fff;
    width: 110px;
    height: 110px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.50);
  }
}
